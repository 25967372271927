






































































import { Component, Vue } from 'vue-property-decorator';
import { SampleClass, SourceType } from '@/store/modules/sample/types';
import { AnswerOption } from '@/store/modules/questionnaire/types';
import Unwatcher from '@/utils/unwatcher';
import { sampleClassFactory } from '@/utils/sample';
import { HomeArea } from '@/store/modules/home-area/types';
import Log from '@/utils/log';

interface Row {
  aoKeyId: number|null;
  areaId: string|null;
  sourceLabel: string,
  classLabel: string;
  use: boolean;
  isNoAnswer: boolean;
}

@Component({
  props: {
    sampleClasses: {}, // Array<SampleClass>
    sourceType: {}, // SourceType
    answerOptions: {}, // UiAnswerOption[]
    homeAreas: {}, // HomeArea[]
  },
  components: {
  },
})
export default class EditSampleClassesComponent extends Vue {
  unwatcher : Unwatcher = new Unwatcher();
  tableData: Row[] = [];

  created() {
    const updateFn = () => { this.tableData = this.getDataTable(); };
    this.unwatcher.push(this.$watch(() => this.$props.sourceType, updateFn));
    this.unwatcher.push(this.$watch(() => this.$props.sampleClasses, updateFn));
    this.unwatcher.push(this.$watch(() => this.$props.answerOptions, updateFn));
    this.unwatcher.push(this.$watch(() => this.$props.homeAreas, updateFn));
  }

  data() {
    return {
      tableData: this.getDataTable(),
    };
  }

  getDataTable() {
    let tableData: Row[] = [];
    if (this.$props.sourceType === SourceType.questionKey) {
      tableData = this.$props.answerOptions.map((ao : AnswerOption) => {
        const sampleClass = (this.$props.sampleClasses as SampleClass[]).find(
          (sc : SampleClass) => sc.answerOptionKeyId === ao.aoKeyId,
        );
        return {
          aoKeyId: ao.aoKeyId,
          areaId: null,
          sourceLabel: ao.label,
          classLabel: sampleClass !== undefined ? sampleClass.label : ao.label,
          use: sampleClass !== undefined,
          isNoAnswer: false,
        };
      });
      /*
      tableData.push({
        aoKeyId: null,
        areaId: null,
        sourceLabel: this.$t('analysis.question.missing-answer').toString(),
        classLabel: this.$t('analysis.question.missing-answer').toString(),
        use: true,
        isNoAnswer: true,
      });
      */
    } else if (this.$props.sourceType === SourceType.homeArea) {
      tableData = this.$props.homeAreas.map((area : HomeArea) => {
        const sampleClass = (this.$props.sampleClasses as SampleClass[]).find(
          (sc : SampleClass) => sc.areaId === area.id,
        );
        return {
          aoKeyId: null,
          areaId: area.id,
          sourceLabel: area.label,
          classLabel: sampleClass !== undefined ? sampleClass.label : area.label,
          use: sampleClass !== undefined,
          isNoAnswer: false,
        };
      });
    } else {
      console.warn('Unexpected source type');
    }
    return tableData;
  }

  onLabelChange(row : Row) {
    if (row.use) {
      const sc = (this.$props.sampleClasses as SampleClass[]).find(
        (sc : SampleClass) => (this.$props.sourceType === SourceType.questionKey
          ? sc.answerOptionKeyId === row.aoKeyId
          : sc.areaId === row.areaId),
      );
      if (sc !== undefined) {
        sc.label = row.classLabel;
      }
    }
  }

  onUseChange() {
    this.addRemoveClasses();
  }

  /**
   * Add/remove classes based on current row.use state.
   * Tries to keep existing classes and only remove/add
   * when necessary.
   */
  addRemoveClasses() {
    Log.log('addRemoveClasses');
    if (this.$props.sourceType === SourceType.questionKey) {
      let nextInsertIndex = 0;
      Log.log('nextInsertIndex', nextInsertIndex);
      this.tableData.forEach((row, index) => {
        if (row.isNoAnswer) return;
        const scIndex = this.$props.sampleClasses.findIndex(
          (sc : SampleClass) => (this.$props.sourceType === SourceType.questionKey
            ? sc.answerOptionKeyId === row.aoKeyId
            : sc.areaId === row.areaId),
        );
        if (!row.use && scIndex !== -1) {
          // Remove sample class
          (this.$props.sampleClasses as SampleClass[]).splice(scIndex, 1);
        } else if (row.use && scIndex === -1) {
          // Insert new sample class
          const sampleClass = sampleClassFactory(null);
          sampleClass.label = row.classLabel;
          sampleClass.answerOptionKeyId = row.aoKeyId;
          sampleClass.areaId = row.areaId;
          (this.$props.sampleClasses as SampleClass[]).splice(nextInsertIndex, 0, sampleClass);
          nextInsertIndex += 1;
        } else {
          // Keep sample class
          nextInsertIndex += 1;
        }
        Log.log('nextInsertIndex', nextInsertIndex, index);
      });
    }
  }
}
