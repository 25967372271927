








































































































































































































import { Component, Vue } from 'vue-property-decorator';
import Log from '@/utils/log';
import { SampleClass, SampleVariable, SourceType } from '@/store/modules/sample/types';
import { HomeArea } from '@/store/modules/home-area/types';
import HistogramBinEditorComponent from '@/components/HistogramBinEditorComponent.vue';
import EditSampleClassesComponent from '@/components/EditSampleClassesComponent.vue';
import resolveLabel from '@/utils/I18nLabel';
import {
  AnswerOption,
  Question, Questionnaire, QuestionnaireType, QuestionType,
} from '@/store/modules/questionnaire/types';
import { sampleClassFactory } from '@/utils/sample';

enum UiSource {
  total = 'total',
  questionnaire = 'questionnaire',
  homeArea = 'homeArea',
  sampleData = 'sampleData',
}

function getUiSource(sv : SampleVariable) : UiSource|null {
  switch (sv.sourceType) {
    case SourceType.total:
      return UiSource.total;
    case SourceType.homeArea:
      return UiSource.homeArea;
    case SourceType.questionKey:
      return UiSource.questionnaire;
    case SourceType.answerOptionKey:
      return UiSource.questionnaire;
    case SourceType.sampleData:
      return UiSource.sampleData;
    default:
      return null;
  }
}

interface UiModel {
  label : string;
  source : UiSource|null; // becomes a string at runtime
  questionKeyId : string|null; // Due to v-model in <select> the type in runtime is string
  answerOptionKeyId : string|null; // Due to v-model in <select> the type in runtime is string
}

interface UiAnswerOption {
  aoKeyId : number;
  label : string;
}

interface UiQuestion {
  qKeyId : number;
  label : string;
  type : QuestionType;
  answerOptions : UiAnswerOption[];
}

@Component({
  props: {
    sampleVariable: {}, // SampleVariable
  },
  components: {
    HistogramBinEditorComponent,
    EditSampleClassesComponent,
  },
})
export default class EditSampleVariableComponent extends Vue {
  isNew : boolean = false;
  isSaving : boolean = false;
  isLoadingQuestionnaire : boolean = false;
  isLoadingHomeAreas : boolean = false;
  uiModel : UiModel = {
    label: '',
    source: null,
    questionKeyId: null,
    answerOptionKeyId: null,
  };

  questions : UiQuestion[] = [];
  homeAreas : HomeArea[] = [];
  questionKeyToIndexMap : any = {};
  answerOptions : UiAnswerOption[] = [];
  editData : SampleVariable|null = null;
  activeTab : number = 0;

  created() {
    // Start loading questionnaire data if it is not yet loaded. This data
    // is only used for sample variable configuration, so it is not eagerly
    // loaded when user selects a survey.
    this.isLoadingQuestionnaire = false;
    this.isLoadingHomeAreas = false;
    if (!this.$store.state.questionnaire.loaded) {
      this.loadQuestionnaireData();
      this.loadHomeAreaData();
    } else {
      this.prepareQuestions();
    }
  }

  data() {
    const isNew = this.$props.sampleVariable.id === null;
    const label = resolveLabel(this.$props.sampleVariable.label, this.$props.sampleVariable.labelTranslations);
    return {
      isNew,
      editData: JSON.parse(JSON.stringify(this.$props.sampleVariable)),
      uiModel: {
        label,
        source: getUiSource(this.$props.sampleVariable),
        questionKeyId: this.$props.sampleVariable.questionKeyId, // is null when answerOptionKeyId is non-null., sorted out in prepareQuestions
        answerOptionKeyId: this.$props.sampleVariable.answerOptionKeyId,
      } as UiModel,
    };
  }

  // During development component can be-remounted with lost state without created() being called.
  mounted() {
    if (!this.isLoadingQuestionnaire && this.answerOptions.length === 0) {
      this.loadQuestionnaireData();
    }
    if (!this.isLoadingHomeAreas && this.homeAreas.length === 0) {
      this.loadHomeAreaData();
    }
  }

  onDelete() {
    if (this.isSaving) return;
    this.isSaving = true;
    this.$buefy.dialog.confirm({
      message: this.$i18n.t(
        this.uiModel.source === UiSource.sampleData
          ? 'edit-sample-variable.delete-confirm-sample-data.text'
          : 'edit-sample-variable.delete-confirm.text',
      ),
      confirmText: this.$i18n.t('nav.ok'),
      cancelText: this.$i18n.t('nav.cancel'),
      type: 'is-assertive',
      container: 'main',
      onConfirm: () => {
        this.$store.dispatch('sample/deleteVariable', this.editData!.id);
        this.$store.commit('query/clear');
        this.$emit('close');
      },
      onCancel: () => {
        this.isSaving = false;
      },
    } as any);
  }

  async loadQuestionnaireData() {
    this.isLoadingQuestionnaire = true;
    try {
      await this.$store.dispatch('questionnaire/load');
      this.prepareQuestions();
    } catch (e) {
      // Do nothing
    }
    this.isLoadingQuestionnaire = false;
  }

  async loadHomeAreaData() {
    this.isLoadingHomeAreas = true;
    try {
      await this.$store.dispatch('homeArea/load');
      this.homeAreas = this.$store.state.homeArea.homeAreas;
    } catch (e) {
      // Do nothing
    }
    this.isLoadingHomeAreas = false;
  }

  /// Prepares this.questions with data from store
  prepareQuestions() {
    const queList = this.$store.state.questionnaire.questionnaires;
    this.questions = [];
    this.questionKeyToIndexMap = {};
    queList.forEach((que : Questionnaire) => {
      if (que.type === QuestionnaireType.backgroundSurvey) {
        this.questions = que.questions.map((q) => ({
          qKeyId: q.qKeyId,
          label: resolveLabel(null, q.label),
          type: q.type,
          answerOptions: q.answerOptions.map((ao : AnswerOption) => ({
            aoKeyId: ao.aoKeyId,
            label: resolveLabel(null, ao.label),
          })),
        }));
      }
    });

    this.questions.forEach((q, index) => {
      this.questionKeyToIndexMap[q.qKeyId as any] = index;

      // Resolve uiModel.questionKeyId in case aoKeyId is provided but not qKeyId
      if (this.uiModel.answerOptionKeyId !== null && this.uiModel.questionKeyId === null) {
        q.answerOptions.forEach((ao) => {
          if (ao.aoKeyId === Number(this.uiModel.answerOptionKeyId)) {
            this.uiModel.questionKeyId = q.qKeyId.toString();
          }
        });
      }
    });
    if (this.uiModel.questionKeyId !== null) {
      this.onSelectQuestion(); // populate this.answerOptions
    }
  }

  onSelectSource(): void {
    if (this.uiModel.source === UiSource.total) {
      if (this.uiModel.label === '') {
        this.uiModel.label = this.$t('sampleVariableTotal').toString();
      }
      this.editData!.classes = [ // Total variable should have exactly one class with no label
        sampleClassFactory(null),
      ];
    }
    if (this.uiModel.source === UiSource.homeArea) {
      if (this.uiModel.label === '') {
        this.uiModel.label = this.$t('sampleVariableHomeAreas').toString();
      }

      // Remove classes from other source
      this.editData!.classes = this.editData!.classes.filter((sc : SampleClass) => sc.areaId !== null);

      // Populate classes
      if (this.editData!.classes.length === 0) {
        Log.log('Populate classes from home areas');
        this.editData!.classes = [];
        this.homeAreas.forEach((area) => {
          const sampleClass = sampleClassFactory(this.editData!.classes.length);
          sampleClass.label = area.label;
          sampleClass.areaId = area.id;
          this.editData!.classes.push(sampleClass);
        });
      }
    }
  }

  onSelectQuestion(): void {
    const selectedQuestion = this.questions[this.questionKeyToIndexMap[Number(this.uiModel.questionKeyId)]];
    this.answerOptions = selectedQuestion.answerOptions;

    if (this.isUnsupportedQuestionType()) {
      return;
    }

    // Auto-assign label from question
    if (this.uiModel.label === '') {
      this.uiModel.label = selectedQuestion.label;
    }

    // Populate single/multi answer question classes
    if (this.isSingleOrMultiAnswerQuestion()) {
      // Remove classes from other source
      const allowedAoKeyIds = this.answerOptions.map((ao : UiAnswerOption) => ao.aoKeyId);
      this.editData!.classes = this.editData!.classes.filter(
        (sc : SampleClass) => sc.answerOptionKeyId !== null && allowedAoKeyIds.includes(sc.answerOptionKeyId),
      );

      // Populate classes
      if (this.editData!.classes.length === 0) {
        this.answerOptions.forEach((ao) => {
          const sampleClass = sampleClassFactory(this.editData!.classes.length);
          sampleClass.label = ao.label;
          sampleClass.answerOptionKeyId = ao.aoKeyId;
          this.editData!.classes.push(sampleClass);
        });
      }
    } else if (this.isIntegerQuestion()) {
      // Remove classes from area and non-int questions
      this.editData!.classes = this.editData!.classes.filter(
        (sc : SampleClass) => sc.answerOptionKeyId === null && sc.areaId === null,
      );
    } else if (this.uiModel.source === UiSource.homeArea) {
      // Remove classes that are not for home areas
      this.editData!.classes = this.editData!.classes.filter(
        (sc : SampleClass) => sc.areaId !== null,
      );
    }

    // Auto-select AO if only one.
    if (this.answerOptions.length === 1) {
      this.uiModel.answerOptionKeyId = this.answerOptions[0].aoKeyId.toString();
      this.onSelectAnswerOption();
    }
  }

  onSelectAnswerOption(): void {
    console.assert(this.isIntegerQuestion());
    if (this.editData?.preFuncExpression === null || this.editData?.preFuncExpression === '') {
      this.editData.preFuncExpression = 'value()';
    }
    // Add a few default histogram bins
    if (this.editData!.classes.length === 0) {
      [
        { label: '0 - 9', binMin: 0, binMax: 10 },
        { label: '10 - 19', binMin: 10, binMax: 20 },
        { label: '20+', binMin: 20, binMax: null },
      ].forEach((data) => {
        const sampleClass = sampleClassFactory(0);
        sampleClass.label = data.label;
        sampleClass.binMin = data.binMin;
        sampleClass.binMax = data.binMax;
        this.editData!.classes.push(sampleClass);
      });
    }
  }

  async onOk() {
    Log.log('onOk');
    this.isSaving = true;
    const sourceType = this.getSourceType();
    if (sourceType === null) {
      await this.$buefy.dialog.alert({
        message: this.$i18n.t('edit-sample-variable.save-error').toString(),
        type: 'is-assertive',
        container: 'main',
      } as any);
      this.isSaving = false;
      return;
    }
    this.editData!.sourceType = sourceType;
    this.editData!.label = this.uiModel.label;
    this.editData!.labelTranslations = {};
    this.editData!.questionKeyId = this.isSingleOrMultiAnswerQuestion() ? Number(this.uiModel.questionKeyId) : null;
    this.editData!.answerOptionKeyId = this.isIntegerQuestion() ? Number(this.uiModel.answerOptionKeyId) : null;
    // Default the preFuncExpression to 'value()' if no function is given for an integer question
    if (this.isIntegerQuestion()
      && (this.editData!.preFuncExpression === null || this.editData!.preFuncExpression?.trim() === '')
    ) {
      this.editData!.preFuncExpression = 'value()';
    }

    const action = this.editData!.id === null ? 'sample/addVariable' : 'sample/updateVariable';
    try {
      await this.$store.dispatch(action, this.editData);
      this.$store.commit('query/clear');
      this.$emit('close');
      this.isSaving = false;
    } catch (e) {
      this.isSaving = false;
      await this.$buefy.dialog.alert({
        message: this.$i18n.t('edit-sample-variable.save-error').toString(),
        type: 'is-assertive',
        container: 'main',
      } as any);
    }
  }

  onClose() {
    this.$emit('close');
  }

  /// get source type from ui model
  getSourceType() : SourceType|null {
    if (this.uiModel.source === UiSource.total) {
      return SourceType.total;
    } else if (this.uiModel.source === UiSource.questionnaire) {
      if (this.isIntegerQuestion()) {
        return SourceType.answerOptionKey;
      } else if (this.isSingleOrMultiAnswerQuestion()) {
        return SourceType.questionKey;
      } else {
        return null;
      }
    } else if (this.uiModel.source === UiSource.homeArea) {
      return SourceType.homeArea;
    }
    return null;
  }

  isIntegerQuestion() {
    if (this.isLoadingQuestionnaire) {
      return this.editData?.answerOptionKeyId !== null;
    }
    if (this.uiModel.questionKeyId === null) {
      return false;
    }
    return this.uiModel.source === UiSource.questionnaire
      && [
        QuestionType.integer,
        QuestionType.integerSum,
      ].includes(this.questions[this.questionKeyToIndexMap[Number(this.uiModel.questionKeyId!)]].type);
  }

  /**
   * Is supported non-int qustion type
   */
  isSingleOrMultiAnswerQuestion() {
    if (this.isLoadingQuestionnaire) {
      return this.editData?.questionKeyId !== null;
    }
    if (this.uiModel.questionKeyId === null) {
      return false;
    }
    return this.uiModel.source === UiSource.questionnaire
      && [
        QuestionType.singleAnswer,
        QuestionType.multiAnswer,
      ].includes(this.questions[this.questionKeyToIndexMap[Number(this.uiModel.questionKeyId!)]].type);
  }

  /// Has a question of unsupported question type been selected?
  isUnsupportedQuestionType() {
    return !this.isLoadingQuestionnaire
      && this.uiModel.source === UiSource.questionnaire
      && this.uiModel.questionKeyId !== null
      && ![
        QuestionType.singleAnswer,
        QuestionType.multiAnswer,
        QuestionType.integer,
        QuestionType.integerSum,
      ].includes(this.questions[this.questionKeyToIndexMap[Number(this.uiModel.questionKeyId!)]].type);
  }
}
