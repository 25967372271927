







































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ViewLayoutComponent from '@/components/ViewLayoutComponent.vue';
import InfoBoxComponent from '@/components/InfoBoxComponent.vue';
import DataTableComponent from '@/components/DataTableComponent.vue';
import DataTableActionComponent from '@/components/DataTableActionComponent.vue';
import EditSampleVariableComponent from '@/components/EditSampleVariableComponent.vue';
import Unwatcher from '@/utils/unwatcher';
import { SampleVariable } from '@/store/modules/sample/types';
import Log from '@/utils/log';
import { sampleVariableFactory } from '@/utils/sample';

@Component({
  components: {
    ViewLayoutComponent,
    InfoBoxComponent,
    DataTableComponent,
    DataTableActionComponent,
    EditSampleVariableComponent,
  },
})
export default class SampleView extends Vue {
  unwatcher : Unwatcher = new Unwatcher();
  public tableData: Array<SampleVariable> = [];
  public isLoadingTableData : boolean = false; // is loading table data?
  public loadError : boolean = false;
  selectedVariable : SampleVariable|null = null;
  editSelectedVariable : boolean = false;

  editSampleVariableComponent = EditSampleVariableComponent; // 'constant' pointer to AggregatedModeComponent

  public async created() {
    const self = this;

    // When filter state changes => new fetch
    this.unwatcher.push(this.$store.watch(
      (state: any, getters: any) => [getters['sample/isLoaded'], getters['sample/isLoadError']],
      (newValue: any|null, oldValue: any|null) => {
        if (newValue !== oldValue) {
          Log.log('Update table data');
          const r = self.getTableData();
          self.tableData = r.tableData;
          self.isLoadingTableData = r.isLoadingTableData;
          self.loadError = newValue[1];
        }
      },
    ));
  }

  /**
   * Loads table data and is used by both data() and store watch
   */
  private getTableData() {
    const data = this.$store.state.sample.variables;

    // IF YOU ADD anything new here ALSO add to watch method above.
    return {
      tableData: data,
      isLoadingTableData: !this.$store.getters['sample/isLoaded'] && !this.$store.getters['sample/isLoadError'],
      loadError: this.$store.getters['sample/isLoadError'],
    };
  }

  data() {
    return this.getTableData();
  }

  onReload() {
    try {
      this.selectedVariable = null;
      this.$store.dispatch('sample/load');
    } catch {
      // Do nothing
    }
  }

  onEditClick(row: SampleVariable) {
    this.selectedVariable = row;
    this.editSelectedVariable = true;
  }

  onCopyClick(row: SampleVariable) {
    this.selectedVariable = JSON.parse(JSON.stringify(row));
    (this.selectedVariable as any).id = null; // Reset id to null to trigger saving as new
    (this.selectedVariable as any).order = null; // will be provided by server
    this.selectedVariable!.canWeightOn = false; // server will fill out this
    this.selectedVariable!.isShare = false; // server will fill out this
    this.selectedVariable!.multiMembership = false; // server will fill out this
    this.editSelectedVariable = true;
  }

  onAddVariable() {
    this.selectedVariable = sampleVariableFactory(null);
    this.editSelectedVariable = true;
  }

  onBack() {
    if (this.$route.params.from === 'weighting') {
      this.$router.push('/weighting');
    } else {
      this.$router.push('/sample');
    }
  }
}
