// Label has been typed as read-write to allow us to locally resolve the translated label
// and update the local copy in the store so that each view render doesn't need to resolve
// the label string.

export enum SourceType {
  total = 'total',
  questionKey = 'questionKey',
  answerOptionKey = 'answerOptionKey',
  homeArea = 'homeArea',
  sampleData = 'sampleData',
}

export interface SampleClass {
  readonly id : number|null; // class id
  readonly order : number; // default order is ASC by this field
  label : string|null; // plain text label or sting starting with 'i18n:' followed by a string key in ui <langKey>.json.
  readonly labelTranslations: any|null; // object with langKey as keys and translated label as values
  readonly collectedSample : number|null; // corresponds to collectedData in RecruitmentRow
  readonly percentage : number|null;
  targetPopulation: number|null; // [user input]
  weightedSample: number|null; // computed
  readonly dataOverTime: Array<any>;

  // source
  answerOptionKeyId: number|null; // int|null
  binMin: number|null; // float|null
  binMax: number|null; // float|null
  areaId: string|null;
}

export interface SampleVariable {
  readonly id : number|null; // variable id
  readonly order : number; // default order is ASC by this field
  label : string|null; // plain text label or sting starting with 'i18n:' followed by a string key in ui <langKey>.json.
  labelTranslations: any|null; // object with langKey as keys and translated label as values
  canWeightOn : Boolean
  weightOn : Boolean; // weight on this variable? [user input]
  isShare: boolean; // if isShare then percentage is used and targetPopulation is in percent
  multiMembership : Boolean; // true when respondents can be member of multiple classes, otherwise false.
  isExpanded : boolean; // [user state]

  classes : Array<SampleClass>;

  // source
  sourceType: SourceType;
  questionKeyId: number|null;
  answerOptionKeyId: number|null; // int|null
  preFuncExpression: string|null;
  filterMin: number|null; // float|null
  filterMax: number|null; // float|null
}

/**
 * During loading:
 * variables: [],// empty list
 * loadError: false,
 *
 * After successful load
 * variables: [...], // 1 or more items
 * loadError: false,
 *
 * After failed load
 * variables: [], // empty list
 * loadError: true,
 */
export interface SampleStateInterface {
  variables: Array<SampleVariable>; // list of sample variables available to currently logged in user
  loadError: boolean; // true => loading failed
}
