







































import { Component, Vue } from 'vue-property-decorator';
import Log from '@/utils/log';
import { SampleClass } from '@/store/modules/sample/types';
import { sampleClassFactory } from '@/utils/sample';
import sample from '@/api/sample';

@Component({
  props: {
    sampleClasses: {}, // Array<SampleClass>
  },
  components: {
  },
})
export default class HistogramBinEditorComponent extends Vue {
  assignLabel(index : number) {
    this.$props.sampleClasses[index].labelTranslations = null;
    const min = this.$props.sampleClasses[index].binMin;
    if (index === this.$props.sampleClasses.length - 1) {
      this.$props.sampleClasses[index].label = `${min}+`;
    } else {
      const max = this.$props.sampleClasses[index].binMax;
      this.$props.sampleClasses[index].label = max - 1 > min ? `${min} - ${max - 1}` : `${min}`;
    }
  }

  onBinMinChange(index : number) {
    this.assignLabel(index);
    if (index > 0) {
      this.$props.sampleClasses[index - 1].binMax = this.$props.sampleClasses[index].binMin;
      this.assignLabel(index - 1);
    }
  }

  /**
   * Get the increment step to use for next bin max
   */
  getAddStep() {
    const defaultStep = 10;
    const classes = this.$props.sampleClasses;
    if (classes.length > 1) { // 2+ bins, use last step size
      const min = classes[classes.length - 2].binMin;
      const max = classes[classes.length - 2].binMax;
      if (min === null || max === null) return defaultStep;
      return Math.max(max - min, 1);
    }
    return defaultStep;
  }

  onAdd() {
    const classes = this.$props.sampleClasses;
    const min = classes.length > 0 ? Number(classes[classes.length - 1].binMin) + this.getAddStep() : 0;
    if (classes.length > 0) {
      classes[classes.length - 1].binMax = min;
    }
    const sampleClass = sampleClassFactory(this.$props.sampleClasses.length);
    sampleClass.binMin = min;
    this.$props.sampleClasses.push(sampleClass);
    this.assignLabel(classes.length - 2);
    this.assignLabel(classes.length - 1);
  }

  onRemove() {
    const classes = this.$props.sampleClasses;
    if (classes.length === 0) {
      return;
    }
    classes.splice(classes.length - 1, 1);
    if (classes.length > 0) {
      classes[classes.length - 1].max = null;
      this.assignLabel(classes.length - 1);
    }
  }
}

